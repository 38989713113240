
<template>
    <div>
        <div
            ref="qrPDF"
            style="
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: center;
            "
        >
            <span style="font-size: 2rem; font-weight: bold">Remote Support</span>
            <div class="span" style="width: 200px; height: 200px">
            <img
                :src="'data:image/jpeg;base64,' + qr"
                style="width: 100%; height: 100%"
            />
            </div>
            <div
            class="info"
            style="
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                font-weight: 500;
                font-size: 1.5rem;
            "
            >
            <span>{{ title }}</span>
            <span>{{ serialno }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        qr: {
            type: String
        },

        title: {
            type: String
        },

        serialno: {
            type: String
        }
    },
}
</script>