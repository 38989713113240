<template>
  <List
    :title="'User'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :extra-actions="extraActions"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    @optionSel="handleOption"
    @create="handleCreate"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
    @selectFilter="handleTabFilter"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages="pages"
    @goToFirst="currentPage = 1"
    @goToLast="currentPage = pages"
    @goToPage="handlePage"
    @changePage="handlePageChange"
  />
  <see-modal
    v-if="see"
    :title="`User Card`"
    :items="toSee"
    @close="see = false"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import SeeModal from "@/components/Ui/CRUD/See.vue";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Users",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      extraActions: [{name: 're-send invite'}],
      tabFilters: [
        {
          name: "All Users",
          filter: "",
          isActive: true,
        },
        {
          name: "Organization Users",
          filter: "users",
          isActive: false,
        },
        {
          name: "Client Users",
          filter: "clients",
          isActive: false,
        },
      ],
      isActive: 0,
      canCreate: [4, 6, 9],
      headItems: ["Username", "Email", "Type", "Role"],
      bodyItems: [],
      editOptions: ["see", "edit", "re-send invite"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      roles: [],
      type: "",
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    SeeModal,
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage(val) {
      this.getData(val, this.type);
    },

    filter() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1, this.type);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1, this.type);
    },

    type(val) {
      this.isActive = this.tabFilters.findIndex((el) => el.filter === val);

      this.getData(1, val);

      if (val === "clients") {
        this.headItems = ["Username", "Email", "Role", "Client"];
        this.fillBodyItems();
      } else if (val === "users") {
        this.headItems = ["Username", "Email", "Role"];
        this.fillBodyItems();
      } else {
        this.headItems = ["Username", "Email", "Type", "Role"];
        this.fillBodyItems();
      }
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),

    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },

    toFilter() {
        if(this.type === 'users') {
            return [
                {
                    name: "roles",
                    options: this.roles.filter((role) => [4,5,6,7,8].includes(role.id))
                },
                {
                    name: "sites",
                    options: this.api("sites"),
                },
            ]
        }
        if(this.type === 'clients') {
            return [
                {
                    name: "roles",
                    options: this.roles.filter((role) => [9,10].includes(role.id))
                },
                {
                    name: "sites",
                    options: this.api("sites"),
                },
            ]
        }
        return [
            {
                name: "roles",
                options:
                    this.getUserProfile.role.id === 9
                    ? this.roles.filter((role) => role.id === 9 || role.id === 10)
                    : this.roles,
            },
            {
                name: "sites",
                options: this.api("sites"),
            },
        ];
    },

    toSee() {
      const item = this.entries.find((el) => el.user_id === this.selItem) || {};
      let toReturn = []
      if (Object.keys(item).length) {
        toReturn = [
          {
            title: "Header",
            image: item.user.avatar ? this.imgURL + item.user.avatar : null,
            value: item.user.name,
            id: item.user.id,
          },
          {
            title: "Phone",
            value: item.user.phone || "undefined",
          },
          {
            title: "Country",
            value: item.user.country || "undefined",
          },
          {
            title: "Email",
            value: item.user.email || "undefined",
          },
          {
            title: "Last Updated",
            value: this.timeSince(item.user.updated_at),
          },
        ];

        if (item.role) {
          toReturn.splice(4, 0, {
            title: "Role",
            value: item.role.name
          });
        }
        else {
          toReturn.splice(4, 0, {
            title: "Roles",
            value: `${item.user.user_client?.client?.name} - ${item.user.user_client?.role_details?.name}` || "undefined"
          });
          toReturn.splice(5, 0, {
            title: "Sites",
            value: `${item.user.user_client?.client?.name} - ${item.user.user_sites.filter(e => e.client_id === item.user.user_client.client.id).map((el) => el.site_details?.name)}` || "undefined"
          });
        }
      }
      return toReturn;
    },
  },
  
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    handlePage(to) {
        this.currentPage = to
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        const sites = [];
        item.user.user_sites
          .filter((el) => el.site_details)
          .map((el) => {
            if (!sites.some((site) => site.name === el.site_details.name))
              sites.push(el.site_details);
          });

        let toReturn = [
          {
            template: "image",
            id: item.user_id,
            data: {
              image: item.user.avatar ? this.imgURL + item.user.avatar : null,
              title: item.user.name,
              description: [
                {
                  light: false,
                  text: `updated ${this.timeSince(item.user.updated_at)}`,
                },
              ],
            },
          },
          {
            template: "normal",
            data: item.user.email || "undefined",
          },
          {
            template: "multiple",
            data: item.role
              ? [{name: item.role.name}]
              : item.user_client_roles.map(e => {
                return {name: e.role_details?.name}
              }) || [{name: "undefined"}]
          },
        ];

        if (this.type === "clients") {
          toReturn.splice(3, 0, {
            template: "normal",
            data: item.user.user_client
              ? item.user.user_client.client.name
              : "undefined",
          });
        }
        if (!this.type.length) {
          toReturn.splice(2, 0, {
            template: "normal",
            data: !item.user_client_roles ? "Client" : !item.user_client_roles.length ? "Organization" : "Client",
          });
        }

        return toReturn;
      });
    },

    async getData(value, filter) {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      const client_id = this.client ? this.client.id : "";
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;
        if (filter === "users") {
          this.type = "users";
          if (!this.filter.length)
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&type=users&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
          else
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&type=users&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
        } else if (filter === "clients") {
          this.type = "clients";
          if (!this.filter.length)
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&type=clients&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
          else
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&type=clients&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
        } else {
          this.type = "";
          if (!this.filter.length)
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
          else
            response = await axiosInstance
              .get(
                `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&client_id=${client_id}&page=${value}`
              )
              .finally(() => (this.loaded = true));
        }
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = responseData.last_page;

        if (!this.roles.length) {
          const roles = await axiosInstance.get(`roles-list`);
          this.roles = roles.data.data;
        }
      }
    },

    filterByItem(data) {
      switch (data[0]) {
        case "roles":
          this.filter = ["roleId", data[1]];
          break;
        case "sites":
          this.filter = ["siteId", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async createNew(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      const toSend = {
        org_id: org.id,
        name: data.Name,
        email: data.Email,
        role_id: data.Roles.id,
        sites: data.Sites ? data.Sites.map((site) => site.id) : [],
        creation_type: "normal",
      };

      try {
        const response = await axiosInstance.post(
          `complete-user-registration`,
          toSend
        );
        if (response) {
          this.handleAlert(response.data.message);
          this.getData(1, this.type);
        }
      } catch (e) {
        this.handleAlert("Something went wrong");
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      switch (data[0]) {
        case "see":
          this.see = true;
          break;
        case "edit":
          this.$router.push({ path: `/data-structure/users/${this.selItem}` });
          break;
        case "delete":
          this.deleteUser();
          break;

        case "re-send invite":
          this.resend(this.selItem);
          break;

        default:
          break;
      }
    },

    async resend(id) {
      const org = JSON.parse(localStorage.getItem("organization"));

      const response = await axiosInstance.post(
        "re-send-user-registration-invitation",
        {
          user_id: id,
          org_id: org.id,
        }
      );
      if (response) {
        this.handleAlert(response.data.message);
      }
    },

    async deleteUser() {
      const res = await axiosInstance.post(`org-user/delete`, {
        user_id: this.selItem,
        org_id: JSON.parse(localStorage.getItem("organization")).id,
      });

      if (res.status === 200) {
        this.$emit("alert", res.data.message);
        this.getData(1, this.type);
      }

      this.selItem = 0;
    },

    handleAction(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance
            .post(`org-user/delete`, {
              user_id: element,
              org_id: org.id,
            })
            .finally(() => {
              this.getData(1, this.type);
            });
        });
        return
      }

      if (data.name === "re-send invite") {
        data.data.forEach(async (element) => {
            const response = await axiosInstance.post(
                "re-send-user-registration-invitation",
                {
                user_id: element,
                org_id: org.id,
                }
            )
            
            if (response) {
                this.handleAlert(response.data.message);
            }
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },

    handleTabFilter(value) {
      this.type = value;
    },

    splitTabFilters() {
      if (this.client) {
        this.tabFilters.splice(0, 2);
        this.type = "clients";
      } else this.getData(1, this.type);
    },

    handleCreate() {
      this.$router.push({ path: "/data-structure/users/create" });
    },
  },

  created() {
    this.splitTabFilters();
  },
};
</script>